import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { MainBanner } from "../components/banners/main-banner"
import JkfContactForm from "../components/jkf-contact-form"

import { MainLayout } from "../components/layouts/layout"
import MainSection from "../components/sections/main-section"
import SEO from "../components/seo"

export default function Home({ data }) {
  return (
    <MainLayout activeNav="/kontakt-os">
      <SEO
        title="God service, gode priser"
        description="Kontakt os for et uforpligtende tilbud, eller hvis du bare har et spørgsmål til en køkken ekspert"
      />
      <MainBanner
        image={data.banner.childImageSharp.base64.base64}
        title="god service og god pris"
        description="Kontakt os her for et godt tilbud"
      />
      <Container fluid>
        <MainSection>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={8}>
                <h3 className="text-center mb-3">
                  Vi er ikke mere end et klik væk..
                </h3>
                <JkfContactForm></JkfContactForm>
              </Col>
            </Row>
          </Container>
        </MainSection>
      </Container>
    </MainLayout>
  )
}

export const query = graphql`
  {
    banner: file(relativePath: { eq: "banners/kitchen_black.jpg" }) {
      id
      childImageSharp {
        base64: sizes(base64Width: 1600, maxHeight: 800, quality: 100) {
          base64
        }
      }
    }
  }
`
